import styled from 'styled-components';

const MaintenanceWrapper = styled.main`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const Maintenance = () => (
    <MaintenanceWrapper>
        <h1>EM MANUTENÇÃO</h1>
    </MaintenanceWrapper>
);
