import styled from 'styled-components';
import { SectionTitle } from './SectionTitle';
import { SectionItem } from './SectionItem';

const entradas = [
    { name: 'Bolinho de camarão - 6 un', price: '26,00' },
    { name: 'Bolinho de shimeji - 6 un', price: '21,00' },
    { name: 'Bolinho de feijoada - 6 un', price: '21,00' },
    { name: 'Franguito - Porção 250gr', price: '19,00' },
    { name: 'Guioza - 6 un', price: '13,80' },
    { name: 'Harumaki - legumes/doce', price: '3,50' },
    { name: 'Harusame - Porção', price: '7,80' },
    { name: 'Nikuman - 3 un', price: '10,90' },
    { name: 'Pastel doce - banana/maçã', price: '4,00' },
    { name: 'Shimeji - Porção 250gr', price: '13,50' },
    { name: 'Sunomono - Porção', price: '3,80' }
];

const SectionEntradasWrapper = styled.section`
  width: 60%;

  @media (max-width: 480px) {
    width: 100%;
    padding-bottom: 20px;
  }
`;

export const SectionEntradas = () => (
    <SectionEntradasWrapper>
        <SectionTitle>ENTRADAS</SectionTitle>
        {entradas.map(entrada => (
            <SectionItem
                key={`entrada-${entrada.name}`}
                {...entrada}
            />
        ))}
    </SectionEntradasWrapper>
);
