import styled from 'styled-components';
import { SectionTitle } from './SectionTitle';
import { SectionItem } from './SectionItem';

const bebidas = [
    { name: 'Água', price: '3,50' },
    { name: 'Água com gás', price: '5,00' },
    { name: 'Caipisake', price: '12,50' },
    { name: 'Caipirosca', price: '14,00' },
    { name: 'Cerveja', price: '16,90' },
];


const SectionBebidasLeftWrapper = styled.section`
  width: 45%;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const SectionBebidasLeft = () => (
    <SectionBebidasLeftWrapper>
        <SectionTitle>BEBIDAS</SectionTitle>
        {bebidas.map(bebida => (
            <SectionItem
                key={`bebidas-left-${bebida.name}`}
                {...bebida}
            />
        ))}
    </SectionBebidasLeftWrapper>
);
