import styled from 'styled-components';
import { Menu, Maintenance } from './pages';

const MAINTENANCE_MODE = 'on';

const AppContainer = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: ${MAINTENANCE_MODE === 'on' ?  '#fff' : '#505050'};
  width: 100%;
  min-height: 80vh;
`;

const App = () => (
  <AppContainer>
    {MAINTENANCE_MODE === 'on' ? <Maintenance /> :  <Menu />}
  </AppContainer>
);

export default App;
