import styled from 'styled-components';
import mainHeader from '../../assets/img/main-header.png';
import whiteYakisumiLogo from '../../assets/img/yakisumi-white.svg';

const Header = styled.div`
  width: 1024px;
  height: 300px;
  background-image: url(${mainHeader});
  background-repeat: no-repeat;
  background-size: 1024px 300px;
  background-color: #1c1f25;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 481px) and (max-width: 800px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
    height: 200px;
    background-size: 480px 200px;
  }
`;

const HeaderText = styled.span`
  color: #fff;
  font-family: 'Exo 2', sans-serif;
  font-size: 2.25em;
  font-weight: bold;
  letter-spacing: 5px;

  @media (min-width: 481px) and (max-width: 800px) {
    font-size: 2em;
  }

  @media (max-width: 480px) {
    font-size: 1.5em;
  }
`;

const HeaderLogo = styled.img`
  width: 120px;
  height: 120px;
  margin-bottom: 20px;

  @media (min-width: 481px) and (max-width: 800px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 480px) {
    width: 85px;
    height: 85px;
  }
`;

const MenuHeader = () => (
    <Header>
        <HeaderLogo src={whiteYakisumiLogo} />
        <HeaderText>YAKISU-MI</HeaderText>
    </Header>
);

export { MenuHeader };
