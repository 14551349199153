import styled from 'styled-components';

const SectionItemWrapper = styled.div`
  padding: 10px 0;

  @media (max-width: 480px) {
    text-align: center;
  }
`;

const SectionItemInfo = styled.span`
  font-family: 'Montserrat', sans-serif; 
  letter-spacing: 1px;
  font-weight: 600;
  color: #fff;
`;

const SectionItemName = styled(SectionItemInfo)`
  width: 60%;
  text-align: left;
  display: inline-block;
  padding-right: 40px;
  letter-spacing: 1px;

  @media (max-width: 480px) {
    padding-right: 20px;
    width: 65%;
  }
`;

const SectionItemPrice = styled(SectionItemInfo)`
  width: 20%;
  text-align: right;
  display: inline-block;
`;

export const SectionItem = ({ name, price }) => (
    <SectionItemWrapper>
        <SectionItemName>{name}</SectionItemName>
        <SectionItemPrice>{price}</SectionItemPrice>
    </SectionItemWrapper>
);