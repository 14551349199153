import styled from 'styled-components';

export const SectionTitle = styled.span`
    display: block;
    color: #E88319;
    letter-spacing: 3px;
    font-weight: bold;
    font-size: 1.35em;
    font-family: source-han-sans-japanese,sans-serif;
    font-weight: 900;
    font-style: normal;
    padding-bottom: 10px;

    @media (max-width: 480px) {
        text-align: center;
    }
`;
