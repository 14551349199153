import styled from 'styled-components';
import { SectionTitle } from './SectionTitle';
import { SectionItem } from './SectionItem';

const yakissobas = [
    { name: 'Camarão', price: '31,90' },
    { name: 'Carne', price: '24,90' },
    { name: 'Frango', price: '21,90' },
    { name: 'Misto', price: '23,90' },
    { name: 'Vegano', price: '31,90' }
];

const SectionYakissobaWrapper = styled.section`
  min-width: 35%;

  @media (max-width: 480px) {
    width: 100%;
    padding-bottom: 20px;
  }
`;

export const SectionYakissoba = () => (
    <SectionYakissobaWrapper>
        <SectionTitle>YAKISSOBA</SectionTitle>
        {yakissobas.map(yakissoba => (
            <SectionItem
                key={`yakissoba-${yakissoba.name}`}
                {...yakissoba}
            />
        ))}
    </SectionYakissobaWrapper>
);
