import styled from 'styled-components';
import { SectionTitle } from './SectionTitle';
import { SectionItem } from './SectionItem';

const bebidas = [
    { name: 'Chá Lata', price: '6,90' },
    { name: 'H2O Limoneto', price: '6,50' },
    { name: 'Refrigerante Lata', price: '6,00' },
    { name: 'Suco - Laranja/Uva', price: '6,50' },
    { name: 'Café Supremo', price: '4,00' }
];

const SectionBebidasRightWrapper = styled.section`
  min-width: 45%;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const SectionCustomTitle = styled(SectionTitle)`
    @media (max-width: 480px) {
        display: none;
    }
`;

export const SectionBebidasRight = () => (
    <SectionBebidasRightWrapper>
        <SectionCustomTitle>&nbsp;</SectionCustomTitle>
        {bebidas.map(bebida => (
            <SectionItem
                key={`bebidas-right-${bebida.name}`}
                {...bebida}
            />
        ))}
    </SectionBebidasRightWrapper>
);
