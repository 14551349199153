import styled from 'styled-components';
import { 
  MenuHeader,
  SectionEntradas,
  SectionYakissoba,
  SectionBebidasLeft,
  SectionBebidasRight
} from '../components/menu';

const MenuContainer = styled.main`
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const MenuRow = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #1c1f25;
  padding: 20px 60px;

  

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
`;

export const Menu = () => (
  <MenuContainer>
    <MenuHeader />
    <MenuRow>
      <SectionEntradas />
      <SectionYakissoba />
    </MenuRow>

    <MenuRow>
      <SectionBebidasLeft />
      <SectionBebidasRight />
    </MenuRow>
  </MenuContainer>
);
